<template>
  <FinalField :name="name" :validate="validate">
    <template v-slot="props">
      <div
          class="radio-input"
          :class="{
              [$attrs.class]: $attrs.class,
              [activeClass]: props.value === value,
              [`radio-input--size-${size}`]: size,
          }"
      >
        <div class="flex space-x-2 items-center  " >
          <input
            type="radio"
            :id="name + value"
            class="mb-1"
            :name="name"
            :value="value"
            v-on="props.events"
            :checked="props.value === value"
            :disabled="disabled"
          />
          <label class="radio-label" :for="name + value">{{ label }}</label>
        </div>
        <span class="form-hint" v-if="hint">{{hint}}</span>
        <span class="form-hint form-error" v-if="props.meta.error && props.meta.touched">
          {{ props.meta.error }}
        </span>
        <div>
          <!-- @slot Extra content for the radio option -->
          <slot name="content" />
        </div>
      </div>
    </template>
  </FinalField>
</template>

<script>
import {FinalField} from 'vue-final-form';

export default {
  name: "RadioInput",
  components: {
    FinalField,
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    validate: {
      type: [Function, Array],
      required: false,
    },
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
    },
    activeClass: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
      validator: function (value) {
        return ['', 'small'].indexOf(value) !== -1;
      },
    },
  },
}
</script>

<style scoped>
  .radio-input {
    @apply py-2;
  }

  .form-hint {
    @apply mt-0 ml-5;
  }

  .radio-label {
    @apply text-sm;
    text-transform: none;
  }

  .radio-input--size-small .radio-label {
    @apply text-2sm;
  }
</style>

<template>
  <div class="flex h-full">
    <loader :loading="loadingPerson || sending" :backdrop="true"/>
    <DetailsSidePanel class="side-panel">
      <template v-slot:heading>resend invitation</template>
      <template v-slot:text>
        <div>Resend invitation to {{person.firstName}} {{person.lastName}} via sms or email.</div>
      </template>
    </DetailsSidePanel>
    <div class="flex-1">
      <div v-if="personLoaded">
        <Form
          :submit="sendInvitation"
          :initialValues="initialValues"
          @change="handleFormChange"
        >
          <div>
            <label class="form-section-title mb-2">
              choose the delivery method to resend invitation:
            </label>

            <RadioInput
                v-for="(identity) in identities"
                :key="identity.id"
                name="identity"
                :value="identity.id"
                :label="`identity id: ${identity.id}`"
                class="delivery-option"
                activeClass="delivery-option--active"
            >
              <template v-slot:content>
                <div class="pl-8">
                  <div class="form-hint mb-1">cognito identity id: {{identity.cognitoIdentityId}}</div>
                  <div class="flex">
                    <RadioInput
                        :name="`identities[${identity.id}]`"
                        :value="DELIVERY_METHODS.EMAIL"
                        :hint="identity.email"
                        :disabled="formValues.identity !== identity.id"
                        label="email"
                        size="small"
                        class="flex-1"
                    />
                    <RadioInput
                        :name="`identities[${identity.id}]`"
                        :value="DELIVERY_METHODS.SMS"
                        :hint="identity.phone || 'not specified'"
                        :disabled="formValues.identity !== identity.id || !Boolean(identity.phone)"
                        label="sms number"
                        size="small"
                        class="flex-1"
                    />
                  </div>
                  <button
                      v-if="identity.persons.length > 0"
                      class="btn-action"
                      type="button"
                      @click="() => visiblePersons[identity.id] = !visiblePersons[identity.id]"
                      :disabled="formValues.identity !== identity.id"
                  >
                    other persons with this identity
                    <icon class="w-4 h-4" :class="{'transform rotate-180': visiblePersons[identity.id]}" name="chevronDown"/>
                  </button>
                  <div v-if="!!visiblePersons[identity.id]">
                    <div v-for="(person) in identity.persons" :key="person.id" class="text-xs">
                      <span class="font-600">{{person.fullName}}</span> {{person.email}}
                    </div>
                  </div>
                </div>
              </template>
            </RadioInput>
            <RadioInput
                name="identity"
                value="other"
                label="other"
                class="delivery-option"
                activeClass="delivery-option--active"
            >
              <template v-slot:content>
                <div class="pl-8 pb-2 pr-2">
                  <div class="form-hint mb-1">select an identity and enter a custom sms number to resend the invitation</div>
                  <div class="flex">
                    <div class="w-1/2 pr-2">
                      <div class="text-sm mt-1 mb-2">identity</div>
                      <SelectInput
                          name="otherIdentityId"
                          :options="identities"
                          optionKey="id"
                          optionLabel="email"
                          :disabled="formValues.identity !== 'other'"
                      />
                    </div>
                    <div class="w-1/2 pl-2">
                      <div class="text-sm mt-1 mb-2">sms number</div>
                      <PhoneInput
                          name="smsPhoneNumber"
                          :disabled="formValues.identity !== 'other' || !formValues.otherIdentityId"
                          :validate="phoneNumber"
                          placeholder="sms number"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </RadioInput>

            <ModalFooter
              :footer="{primaryButton:  'send' , tertiaryButton: 'cancel'}"
              :tertiary="close"
              :disablePrimary="sendDisabled"
            />
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
  import {isEmpty} from 'lodash-es';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from '@/mixins/NotifyMixin'
  import ValidatorMixin from "@/components/form/ValidatorMixin";
  import ModalFooter from "@/components/ui/modals/ModalFooter";
  import Form from "@/components/form/Form";
  import PhoneInput from "@/components/form/PhoneInput";
  import RadioInput from "@/components/auth/form/RadioInput";
  import SelectInput from '@/components/form/SelectInput';
  import Loader from '@/components/ui/Loader';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import Icon from '@/components/ui/Icon';

  export default {
    name: 'PersonReinviteView',
    components: {
      SelectInput,
      DetailsSidePanel,
      ModalFooter,
      Form,
      PhoneInput,
      RadioInput,
      Loader,
      Icon,
    },
    mixins: [
      ConfirmationMixin,
      ModalNavigation,
      NotifyMixin,
      ValidatorMixin,
    ],
    data: function() {
      return {
        loadingPerson: true,
        sending: false,
        initialValues: {},
        visiblePersons: {},
        formValues: {},
        person: {},
        DELIVERY_METHODS: {
          EMAIL: 'email',
          SMS: 'phone', // value is a field in identity
        },
      }
    },
    computed: {
      identities: function () {
        return this.person?.identities.map((item) => ({
          ...item,
          persons: item.persons.reduce((acc, item) => {
            if (item.id !== this.person.id) {
              acc.push({
                fullName: `${item.firstName} ${item.lastName}`,
                email: item.emails[0].email,
              })
            }
            return acc;
          }, []),
        })) || [];
      },
      personId: function () {
        return this.$route.params.personId;
      },
      personLoaded() {
        return !isEmpty(this.person);
      },
      sendDisabled() {
        return this.formValues.identity === 'other' && isEmpty(this.formValues.smsPhoneNumber);
      },
    },
    methods: {
      handleFormChange(state) {
        this.formValues = state.values;
      },

      sendInvitation() {
        const { identity, identities, otherIdentityId, smsPhoneNumber } = this.formValues;
        let destination, payload = {};

        if (identity === 'other') {
          destination = smsPhoneNumber;
          payload = {
            identityId: otherIdentityId,
            data: {
              smsPhoneNumber,
            }
          }
        } else {
          const targetIdentity = this.identities.find(({ id }) => identity === id);
          const deliveryMethod = identities[identity];
          destination = targetIdentity[deliveryMethod];
          payload = {
            identityId: identity,
            data: deliveryMethod === this.DELIVERY_METHODS.SMS
                ? { smsPhoneNumber: destination }
                : { email: destination },
          }
        }

        this.requestConfirmation({
          confirmationMessage: `resend invitation to ${destination}?`,
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'success'
        })
            .then(confirmed => {
              if (!confirmed) {
                return null;
              }

              this.sending = true;

              return this.$raaDataProvider.create('reInviteIdentity', payload)
                  .then(() => {
                    this.notifySuccess('invitation has been sent');
                    this.close();
                  })
                  .catch(error => this.notifyError(error.message))
                  .finally(() => {
                    this.sending = false;
                  });
            });
      },

      fetchPerson() {
        return this.$raaDataProvider.getOne('persons', { id: this.personId })
            .then((result) => {
              if (result.identities.length === 0) {
                this.close();
                return;
              }

              const { identity } = this.$route.query;
              this.initialValues = {
                identity: identity || result.identities[0].id,
                identities: result.identities.reduce((acc, item) => ({ ...acc, [item.id]: this.DELIVERY_METHODS.EMAIL}), {}),
              };
              this.person = result;
              this.loadingPerson = false;
            })
            .catch((error) => this.notifyError(error.message));
      },
    },
    mounted() {
      this.fetchPerson();
    }
  };
</script>

<style scoped>
  .delivery-option {
    @apply border px-2 py-3 mb-6;
    border-radius: 4px;
  }

  .delivery-option--active {
    @apply border-active-500;
  }

  .delivery-option :deep(.form-col) {
    margin: 0;
  }
</style>
